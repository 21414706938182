body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #121212;
    font-family: 'Roboto', sans-serif;
    margin: 0;
    color: #ffffff;
    touch-action: none; /* Отключает оттягивание view */
}

#gameContainer {
    text-align: center;
    padding: 20px;
    width: 100%;
    max-width: 400px;
    border: 2px solid #6a11ee;
    border-radius: 10px;
    background-color: #1f1f1f;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden; /* Запрещает прокрутку содержимого */
}

.board {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
}

.position {
    width: 100px;
    height: 145.2px;
    border: 2px solid #6a11ee;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.cards {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card {
    width: 100px;
    height: 145.2px;
}

.deck-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

#drawButton {
    border: none;
    background: none;
    cursor: pointer;
}

.button-card {
    width: 100px;
    height: 145.2px;
    border-radius: 5px;
}

.cardCount {
    margin-top: 10px;
    font-size: 24px;
    color: #ffffff;
}

#stats {
    margin-top: 20px;
    font-size: 16px;
    color: #bbbbbb;
}